import React, { Component } from "react";
import { connect } from "react-redux";
import UserInfo from "./UserInfo";
import Nav from "./Nav";
import Logo from "../../assets/images/appgain.svg";
class SideBar extends Component {
  state = {};

  render() {
    let { location, backgroundColor, enableBackgroundImage, backgroundImage } =
      this.props;

    return (
      <div
        className={`sidebar ${
          this.props.mobileMenu ? "displayMenu" : "nonDisplayMenue"
        }`}
        data-color={backgroundColor}
        data-image={backgroundImage}
      >
        <div className="brand">
          <a href="#" className="brand-name">
            <img src={Logo} alt="logo" className="logo" />
          </a>
        </div>

        <div className="sidebar-wrapper">
          <UserInfo />
          <div className="line"></div>
          <Nav mobileMenu={this.props.mobileMenu} />
        </div>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: enableBackgroundImage
              ? "url(" + backgroundImage + ")"
              : null,
          }}
        ></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
});

export default connect(mapStateToProps)(SideBar)
