

import { Box, Modal, Typography } from "@material-ui/core";
import React from "react";
import { IoIosClose } from "react-icons/io";
import "./modal.css";
const ConfirmModal = ({ message, children, show, handleClose }) => {
  return (
    <Modal
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ display: "flex" }}
    >
      <Box className="modalContainer">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="modalTitle"
        >
          Appgain
          <IoIosClose onClick={handleClose} size={24} />
        </Typography>
        <Typography
          id="modal-modal-description"
          className="modalBody"
          component="div"
        >
          {message}
        </Typography>
        <Typography className="ModalFooter" component="div">
          <button className="cancelBtn btn" onClick={handleClose}>
            Cancel
          </button>
          {children}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
