import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./CareersDataTable.css";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import IconBtn from "../IconBtn/IconBtn";

const CareersDataTable = ({
  career,
  deleteCareer
}) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };

  const deleteCareerHandler = () => {
    deleteCareer(career.objectId);
  }
  return (
    <React.Fragment>
      <tr>
        <td>{career.PositionName}</td>
        <td>{career.PositionLocation}</td>
        <td>{career.ApplyURL}</td>
        <td>{career.Type}</td>
        <td dangerouslySetInnerHTML={{ __html: career.Description }}
        ></td>
        <td>
          <Link to={`/editCareer/${career.objectId}`}>
            <IconBtn
              title={"Edit"}
              iconName={"pencil"}
              btnBehaviourClass="edit"
            />
          </Link>
        </td>
        <td>
          <IconBtn
            btnBehaviourClass={"delete"}
            iconName="trash"
            onClick={openConfirmModalHandler}
          />
        </td>

      </tr>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${career.PositionName}?`}
          setShow={setShow}
          show={show}
        >
          <button
            className="btn btn-danger"
            onClick={deleteCareerHandler}
          >
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default CareersDataTable;
