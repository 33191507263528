import "./AddTeams.css";
import { FormControl, FormGroup, Button } from "react-bootstrap";

import React, { useState, useEffect } from "react";

import { connect } from "react-redux";
import {
  getTeam,
  createTeam,
  editTeam,
  uploadTeamLogo,
} from "../../reducers/Teams";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import _ from "lodash";
const AddTeam = ({
  history,
  isPending,
  team,
  getTeam,
  createTeam,
  editTeam,
  uploadLogo,
  teamLogo,
}) => {
  const teamId =
    window.location.hash.includes("editTeam") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log("teamId: ", teamId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [logo, setLogo] = useState("");
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState("");
  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedLogoUrl, setSelectedLogoUrl] = useState("");
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (teamId) {
      getTeam(teamId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [teamId]);

  const cancel = () => {
    history.go(-1);
  };

  useEffect(() => {
    // if (selectedLogo) {
    //   if (selectedLogo.size > 512000) {
    //     setShow(true);
    //     selectedLogo("");
    //   }
    // }
    //selectedLogo,
    if (teamLogo) {
      console.log("teamLogo: ", teamLogo);
      setSelectedLogoUrl(teamLogo);
    }
  }, [ teamLogo]);

  const storeImageHandler = e => {
    e.preventDefault();
    setSelectedLogo(e.target.files[0]);
  };

  const submitTeamHandler = e => {
    e.preventDefault();
    const teamData = {
      Name: name,
      Title: title,
      Logo: selectedLogoUrl,
    };

    if (isEditingMode) {
      editTeam(teamId, teamData);
    } else {
      createTeam(teamData);
    }
    // wipeAmbassadorImageData();
    history.push("/teams");
  };

  useEffect(() => {
    if (!!team && teamId) {
      setName(team.Name);

      setTitle(team.Title);
      setSelectedLogoUrl(team.Logo);
      setEdit(true);
    }
  }, [team]);

  const uploadLogoHandler = () => {
    uploadLogo(selectedLogo, name);
    setEdit(true);
  };

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <h2 className='title'>
        {isEditingMode ? `Edit Team: ${name}` : "Add New Team"}
      </h2>
      <form
        className='container'
        style={{ minHeight: "auto" }}
        onSubmit={submitTeamHandler}
      >
        <FormGroup>
          <div className='fieldDiv'>
            <label id='teamName'>Name</label>
            <FormControl
              type='text'
              value={name}
              onChange={e => setName(e.target.value)}
              name='teamName'
              htmlFor='teamName'
              placeholder='Enter Name'
              required
            />
          </div>

          <div className='fieldDiv'>
            <label id='title'>Title</label>
            <FormControl
              type='text'
              value={title}
              onChange={e => setTitle(e.target.value)}
              name='teamTitle'
              htmlFor='teamTitle'
              placeholder='Enter Title'
              required
            />
          </div>

          <div className='fieldDiv'>
            <label>
              {isEditingMode ? "Change" : "Upload"} Photo
            </label>
            <div className='file-input'>
              {/* SVG support */}
              <input
                type='file'
                accept='image/*'
                placeholder='Choose Team Logo'
                onChange={storeImageHandler}
                className='file'
                id='file'
              />
              <label htmlFor='file'>
                {selectedLogo || isEditingMode ? "Change " : "Select "} Photo
              </label>
            </div>
            <div style={{ marginTop: "20px" }}>
              {selectedLogo && (
                <React.Fragment>
                  <p>Please Upload the photo</p>
                  <button
                    onClick={uploadLogoHandler}
                    className='btn btn-success w-100 my-3'
                  >
                    Upload photo
                  </button>
                </React.Fragment>
              )}
            </div>
          </div>
        </FormGroup>
        <div className='btns'>
          <Button
            disabled={!edit}
            className='submitBtn'
            type='submit'
            bsStyle='primary'
          >
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className='CancelBtn' bsStyle='primary' onClick={cancel}>
            Cancel
          </Button>
        </div>
        {show && (
          <ConfirmModal
            handleClose={() => setShow(false)}
            handleShow={() => setShow(true)}
            message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
            setShow={setShow}
            show={show}
          ></ConfirmModal>
        )}
        <div className='error'>
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = state => ({
  isPending: state.Teams.isPending,
  team: state.Teams.team,
  teamLogo: state.Teams.teamLogo,
});

const actions = dispatch => ({
  getTeam: teamId => dispatch(getTeam(teamId)),
  createTeam: newTeamData => dispatch(createTeam(newTeamData)),
  editTeam: (teamId, newTeamData) => dispatch(editTeam(teamId, newTeamData)),
  uploadLogo: (image, id) => dispatch(uploadTeamLogo(image, id)),
});

export default connect(mapStateToProps, actions)(AddTeam);
