import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getCareers,
  deleteCareer,
} from "../../reducers/Careers";
import CareersDataTable from '../../components/CareersDataTable';
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import _ from 'lodash';
import './Careers.css';

const Careers = ({
  careers,
  getCareers,
  deleteCareer,
  isPending,
  history,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getCareers();
  }, []);

  useEffect(() => {
    setData(careers);
  }, [careers]);

  const handleAddCase = () => {
    history.push('addCareer');
  }
  function reload(){

    try {
      
        fetch(
        'https://api.vercel.com/v1/integrations/deploy/prj_2avFJzRCmNi71mYejKrW91VtgFAa/j71wYLiEWQ',{
        method:'GET',
       headers: {
          'Content-Type': 'application/json',
        },
       })
       alert('publishing done successfully')

      } catch (error) {
        console.log("error", error);
        alert('failed')

    }
  }
  if (isPending) {
    return <LoadingSpinner />;
  }
  console.log('careers: ', Careers);
  return (
    <div className="card">
      <div className="header">
        <h4 className="title">
          Careers
          <button
            onClick={reload}
            className="btn btn-primary addProjectBtn me-2"
          >
            publish to the website
          </button> 
          <button
            onClick={handleAddCase}
            className="btn btn-primary addProjectBtn"
          >
            <i className="fa fa-plus"></i> Add a Job
          </button>
        </h4>
      </div>
      <div
        className="content table-responsive table-full-width"
        style={{ width: "100%" }}
      >
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>Position Name</th>
              <th>Position Location</th>
              <th>Apply URL</th>
              <th>Type</th>
              <th>Description</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) && data.map((career) => (
              <CareersDataTable
                key={career.objectId}
                career={career}
                isPending={isPending}
                deleteCareer={deleteCareer}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    careers: state.Careers.careers,
    careersConfig: state.Careers.careersConfig,
    isPending: state.Careers.isPending,
  }),
  (dispatch) => ({
    getCareers: () => dispatch(getCareers()),
    deleteCareer: (id) => dispatch(deleteCareer(id)),
  })
)(Careers);
