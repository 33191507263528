import { appgainApi } from "../api";

const defaultState = {
  suits: [],
  suit: {},

  error: null,
  isPending: null,
  loaded: false,
  msg: "",
};

const url = "list_system_suits";

const EDIT_SUIT = "EDIT_SUIT";
const LOAD_SUITS_LIST = "LOAD_SUITS_LIST";
const SUITS_PENDING = "SUITS_PENDING";
const SUITS_ERROR = "SUITS_ERROR";

// all suits
export const getSuits = () => async (dispatch, getState) => {
  dispatch({ type: SUITS_PENDING });
  try {
 
    const data = await appgainApi(
      `${url}`,
      "get",
    );
    console.log(data,'data');
    dispatch({
      type: LOAD_SUITS_LIST,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SUITS_ERROR,
      error,
    });
  }
};

// EDIT _suspension
export const editSuit = (id) => async (dispatch, getState) => {
  dispatch({ type: SUITS_PENDING });
  try {
    await appgainApi(
      `toggle_app_suspension/${id}`,
      "get",
     
    );
    dispatch({
      type: EDIT_SUIT,
      payload: { id },
    });
  } catch (error) {
    dispatch({ type: SUITS_ERROR, error });
  }
};
export const updateSuit = (id)=>async (dispatch,getState)=>{
  
}



export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_SUITS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        suits: action.payload,
        loaded: true,
      };
  
    case SUITS_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: false,
      };

    case SUITS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        suits: [],
        loaded: true,
      };


    case EDIT_SUIT:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        // suits: state.suits.map(suit =>
        //     suit.id == action.payload.id ? action.payload.newSuitData : suit
        // ),
      };
   

    default:
      return state;
  }
}
