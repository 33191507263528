import React, { useEffect, useState } from "react";
import { Table, DatePicker, Dropdown, Space, Menu, Alert } from "antd";
import "./jobs.css";
import { Backdrop, Fade, Grid, Modal } from "@material-ui/core";

import { connect } from "react-redux";
import {
  deleteScheduled,
  getScheduled,
  getScheduleds,
  startScheduled,
} from "../../reducers/ScheduledJobs";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  AiOutlineCalendar,
  AiOutlineDelete,
  AiOutlineExport,
} from "react-icons/ai";
import { FiRotateCcw } from "react-icons/fi";
import { RiArrowDownSFill } from "react-icons/ri";
import moment from "moment";
import toaster from "../../modules/toaster";
import { ToastContainer } from "react-toastify";
const ScheduledJobs = ({
  getScheduleds,
  deleteScheduled,
  scheduleds,
  isPending,
  startScheduled,
  getScheduled,
  job,
}) => {
  const [tabOption, setTabOption] = useState("scheduled");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSearchModalOpen, setSearchIsModalOpen] = useState(false);

  const [title, setTitle] = useState("");
  const [msg, setMsg] = useState("");
  const [time, setTime] = useState("This Month");
  const [dateString, SetDateString] = useState([]);
  const [suit_id, setSuit_id] = useState("");
  const [campaign_id, setCampaign_id] = useState("");
  const [search, setSearch] = useState("");
  const [JobId, setJobId] = useState("");
  const [message, setMessage] = useState("");
  const [searchType, setSearchType] = useState("");

  // get dates
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const startOfMonth = moment().startOf("month").format("YYYY-MM-DD");
  const endOfMonth = moment().endOf("month").format("YYYY-MM-DD");
  let year = moment().year();
  const startDayOfYear = `${year - 1}-01-01`;
  const lastDayOfYear = `${year - 1}-12-31`;
  const showModal = (data) => {
    if (data.data) {
      if (data.data.appPush) {
        setTitle(data.data.appPush.title);
        setMsg(data.data.appPush.message);
      } else if (data.data.webPush) {
        setTitle("No Title");
        setMsg(data.data.webPush.message);
      } else if (data.data.SMS) {
        setTitle("No Title");
        setMsg(data.data.SMS.message);
      } else if (data.data.email) {
        setTitle(data.data.email?.subject);
        setMsg(data.data.email.message);
      } else if (data.data.WHATSAPP) {
        setTitle("No Title");
        setMsg(data.data.WHATSAPP.message);
      }
    } else {
      setTitle("No Data");
    }
    setIsModalOpen(true);
  };
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  let scheduled = {};
  let allscheduleds = [];
  if (Object.keys(job).length != 0) {
    console.log(job?.data?.data);
    const date = new Date(job?.data?.eta);
    let eta = date.toLocaleString("en-US", options);

    scheduled = {
      ...job?.data?.request,
      eta: eta,
      Title: job.data?.title,
      Data: (
        <button
          className="jopBtn"
          onClick={() => {
            showModal(job?.data);
          }}
        >
          <AiOutlineExport size={24} color="#024DA1" />
        </button>
      ),
    };
    allscheduleds.push(scheduled);

    // allscheduleds.push(job.data);
  } else {
    scheduleds?.forEach((element) => {
      const date = new Date(element.eta);
      let eta = date.toLocaleString("en-US", options);

      scheduled = {
        ...element.request,
        eta: eta,
        Title: element.request?.args?.[0]?.title,
        Data: (
          <button
            className="jopBtn"
            onClick={() => {
              showModal(element.request?.args[0]);
            }}
          >
            <AiOutlineExport size={24} color="#024DA1" />
          </button>
        ),
      };
      allscheduleds.push(scheduled);
    });
  }
  console.log(allscheduleds, "allscheduleds");
  useEffect(() => {
    getScheduleds(`scheduled?start=${startOfMonth}&end=${endOfMonth}`);
  }, []);

  const startScheduledImmediately = (id) => {
    try {
      startScheduled("12");
      // getScheduledsByDate();
      return <Alert message="started successfully" type="success" />;
    } catch (error) {
      toaster.error(`something went wrong`);
    }
  };

  const deleteScheduleds = (id) => {
    try {
      deleteScheduled(id);
      toaster.success(`deleted successfully`);
      getScheduledsByDate();
    } catch (error) {
      toaster.error(`something went wrong`);
    }
  };

  const columns = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "Title",
      key: "Title",
    },

    {
      title: "Scheduled at",
      dataIndex: "eta",
      key: "eta",
    },
    {
      title: "Data",
      dataIndex: "Data",
      key: "Data",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <>
            <div>
              <FiRotateCcw
                size={24}
                color="#24BA56"
                onClick={() => {
                  startScheduledImmediately(record.id);
                }}
                style={{ cursor: "pointer" }}
              />
              <AiOutlineDelete
                size={24}
                color="#FF0303"
                onClick={() => {
                  deleteScheduleds(record.id);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          </>
        );
      },
    },
  ];
  const TabOption = (option) => {
    setTabOption(option);
  };
  if (isPending) {
    return <LoadingSpinner />;
  }

  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleSearchClose = () => {
    setSearchIsModalOpen(false);
  };
  const { RangePicker } = DatePicker;
  const onChange = (value, dateString) => {
    SetDateString(dateString);
  };

  const getScheduledsByDate = () => {
    if (dateString.length > 0) {
      getScheduleds(`${tabOption}?start=${dateString[0]}&end=${dateString[1]}`);
    } else {
      if (time === "Today") {
        getScheduleds(`${tabOption}?start=${todayDate}&end=${todayDate}`);
      } else if (time === "This Month") {
        getScheduleds(`${tabOption}?start=${startOfMonth}&end=${endOfMonth}`);
      } else if (time === "Last Year") {
        getScheduleds(
          `${tabOption}?start=${startDayOfYear}&end=${lastDayOfYear}`
        );
      } else if (time === "All Time") {
        getScheduleds(`${tabOption}`);
      }
    }
  };
  const getSpecificScheduled = () => {
    let apiQuery=`scheduled?${suit_id && `suit_id=${suit_id}&`}${
      campaign_id && `campaign_id=${campaign_id}&`
    }${search && `search=${search}&`}${message && `automessage_id=${message}&`}
    `
    apiQuery = apiQuery.substring(0, apiQuery.lastIndexOf('&'));

    getScheduleds(apiQuery);
  };
  const menu = (
    <Menu>
      <div style={{ padding: ".5em" }}>
        <p>Date Range</p>
        <Space direction="vertical" size={12}>
          <RangePicker onChange={onChange} />
        </Space>
      </div>

      <button
        className={`${
          time === "Today" ? "timeSelectionActive" : "timeSelection"
        } `}
        onClick={() => {
          setTime("Today");
          SetDateString([]);
        }}
      >
        Today
      </button>

      <button
        className={`${
          time === "This Month" ? "timeSelectionActive" : "timeSelection"
        } `}
        onClick={() => {
          setTime("This Month");
          SetDateString([]);
        }}
      >
        This Month
      </button>

      <button
        className={`${
          time === "Last Year" ? "timeSelectionActive" : "timeSelection"
        } `}
        onClick={() => {
          setTime("Last Year");
          SetDateString([]);
        }}
      >
        Last Year
      </button>

      <button
        className={`${
          time === "All Time" ? "timeSelectionActive" : "timeSelection"
        } `}
        onClick={() => {
          setTime("All Time");
          SetDateString([]);
        }}
      >
        All Time
      </button>
      <Menu.Item className="applyContainer">
        <button className="cancelBtn">Cancel</button>
        <button
          className="applyBtn"
          onClick={() => {
            getScheduledsByDate();
          }}
        >
          Apply
        </button>
      </Menu.Item>
    </Menu>
  );
  const filter = (
    <Menu>
      <button
        className="timeSelection"
        onClick={() => {
          setSearchIsModalOpen(true);
          setSearchType("JobId");
        }}
      >
        JobId
      </button>
      {/*  getScheduleds(`scheduled?suit_id=${suit_id}`) */}
      <button
        className="timeSelection"
        onClick={() => {
          setSearchIsModalOpen(true);
          setSearchType("SuitId");
        }}
      >
        SuitId
      </button>
      <button
        className="timeSelection"
        onClick={() => {
          setSearchIsModalOpen(true);
          setSearchType("CampaignId");
        }}
      >
        CampaignId
      </button>

      <button
        className="timeSelection"
        onClick={() => {
          setSearchIsModalOpen(true);
          setSearchType("message");
        }}
      >
        message{" "}
      </button>
      <button
        className="timeSelection"
        onClick={() => {
          setSearchIsModalOpen(true);
          setSearchType("anything");
        }}
      >
        any thing
      </button>
      <Menu.Item className="applyContainer">
        <button className="cancelBtn">Cancel</button>
        <button
          className="applyBtn"
          onClick={() => {
            JobId ? getScheduled(JobId) : getSpecificScheduled();
          }}
        >
          Apply
        </button>
      </Menu.Item>
    </Menu>
  );
  return (
    <div className="card">
      <div className="filtersContainer">
        <Dropdown overlay={filter} trigger={["click"]}>
          <div className="timeFilter">Filter By</div>
        </Dropdown>
        <div className="filterContainer">
          <h4>
            Today: <span>{new Date().toLocaleString("en-US", options)}</span>
          </h4>
          <Dropdown overlay={menu} trigger={["click"]}>
            <div className="timeFilter">
              <AiOutlineCalendar size={24} />
              <button className="jopBtn">
                {dateString.join(" : ") || time}
              </button>
              <RiArrowDownSFill size={24} />
            </div>
          </Dropdown>
        </div>
      </div>

      <Grid
        container
        spacing={3}
        className="typeChoosen"
        direction="row"
        justifyContent="start"
        alignItems="center"
      >
        <Grid item>
          <button
            className={`${
              tabOption === "scheduled" ? "activeTap" : ""
            } tabButton`}
            onClick={() => {
              TabOption("scheduled");
              getScheduleds(
                `scheduled?start=${startOfMonth}&end=${endOfMonth}`
              );
            }}
          >
            Pending
          </button>
        </Grid>
        <Grid>
          <button
            className={`${
              tabOption === "revoked" ? "activeTap" : ""
            } tabButton`}
            onClick={() => {
              TabOption("revoked");
              getScheduleds(`revoked?start=${startOfMonth}&end=${endOfMonth}`);
            }}
          >
            Deleted
          </button>
        </Grid>
      </Grid>
      <Table dataSource={allscheduleds} columns={columns} scroll></Table>

      {/* Data modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal"
      >
        <Fade in={isModalOpen}>
          <div className="paper">
            <p id="transition-modal-title">
              {title ? (
                <>
                  Title: <span>{title}</span>
                </>
              ) : (
                "No Data"
              )}
            </p>

            {msg && (
              <p id="transition-modal-description">
                Message: <span> {msg}</span>{" "}
              </p>
            )}
          </div>
        </Fade>
      </Modal>
      {/* search for modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isSearchModalOpen}
        onClose={handleSearchClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        className="modal"
      >
        <Fade in={isSearchModalOpen} style={{ width: "400px" }}>
          <div className="paper">
            <p id="transition-modal-title">Enter {searchType}</p>

            {searchType === "JobId" && (
              <input
                type="text"
                value={JobId}
                style={{ padding: "4px", width: "100%" }}
                onChange={(e) => {
                  setJobId(e.target.value);
                }}
              />
            )}
            {searchType === "SuitId" && (
              <input
                type="text"
                value={suit_id}
                style={{ padding: "4px", width: "100%" }}
                onChange={(e) => {
                  setSuit_id(e.target.value);
                }}
              />
            )}
            {searchType === "CampaignId" && (
              <input
                type="text"
                value={campaign_id}
                style={{ padding: "4px", width: "100%" }}
                onChange={(e) => {
                  setCampaign_id(e.target.value);
                }}
              />
            )}
            {searchType === "message" && (
              <input
                type="text"
                value={message}
                style={{ padding: "4px", width: "100%" }}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              />
            )}
            {searchType === "anything" && (
              <input
                type="text"
                value={search}
                style={{ padding: "4px", width: "100%" }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            )}
          </div>
        </Fade>
      </Modal>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </div>
  );
};

export default connect(
  (state) => ({
    scheduleds: state.ScheduledJobs.jobs,
    isPending: state.ScheduledJobs.isPending,
    job: state.ScheduledJobs.job,
  }),
  (dispatch) => ({
    getScheduleds: (url) => dispatch(getScheduleds(url)),
    deleteScheduled: (id) => dispatch(deleteScheduled(id)),
    startScheduled: (id) => dispatch(startScheduled(id)),
    getScheduled: (id) => dispatch(getScheduled(id)),
  })
)(ScheduledJobs);
