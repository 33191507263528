import { appBackendAppgainUrl, getConfigHeaders } from "../api";
import uploadOnS3 from "../helpers/uploadOnS3";

const defaultState = {
  experts: [],
  expert: {},
  expertLogo: '',
  error: null,
  isPending: null,
  loaded: false,
  msg: "",
};

const url = 'Experts';

const EDIT_EXPERT = "EDIT_EXPERT";
const CREATE_EXPERT = "CREATE_EXPERT";
const DELETE_EXPERT = "DELETE_EXPERT";
const LOAD_EXPERTS_LIST = "LOAD_EXPERTS_LIST";
const GET_EXPERT = "GET_EXPERT";
const EXPERTS_PENDING = "EXPERTS_PENDING";
const POST_EXPERTS_LOGO = "POST_EXPERTS_LOGO";
const EXPERTS_ERROR = "EXPERTS_ERROR";

export const getExperts = () => async (dispatch, getState) => {
  dispatch({ type: EXPERTS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: LOAD_EXPERTS_LIST,
      payload: data.results
    })
  } catch (error) {
    dispatch({
      type: EXPERTS_ERROR,
      error
    })
  }
};

export const getExpert = (id) => async (dispatch, getState) => {
  dispatch({ type: EXPERTS_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${id}`,
      "get",
      getConfigHeaders()
    )
    dispatch({
      type: GET_EXPERT,
      payload: data
    })
  } catch (error) {
    dispatch({ type: EXPERTS_ERROR, error })
  }
}

export const editExpert = (id, newExpertData) => async (dispatch, getState) => {
  dispatch({ type: EXPERTS_PENDING });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newExpertData,
      getConfigHeaders()
    )
    dispatch({
      type: EDIT_EXPERT,
      payload: { id, newExpertData }
    });
  } catch (error) {
    dispatch({ type: EXPERTS_ERROR, error });
  }
};

export const createExpert = (newExpertData) => async (dispatch, getState) => {
  dispatch({ type: EXPERTS_PENDING });
  try {
    const res = await appBackendAppgainUrl(
      `${url}`,
      "post",
      newExpertData,
      getConfigHeaders()
    );
    dispatch({
      type: CREATE_EXPERT,
      payload: res
    });
  } catch (error) {
    dispatch({ type: EXPERTS_ERROR, error });
  }
};

export const deleteExpert = (id) => async (dispatch, getState) => {
  dispatch({ type: EXPERTS_PENDING });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "delete",
      getConfigHeaders()
    )
    dispatch({
      type: DELETE_EXPERT,
      payload: id
    })
  } catch (error) {
    dispatch({ type: EXPERTS_ERROR, error });
  }
};

// Logo Upload
export const uploadExpertLogo = (logo, name) => async (dispatch) => {
  dispatch({
    type: EXPERTS_PENDING,
  });
  try {
    const data = await uploadOnS3(logo, name);
    console.log('data: ', data);
    dispatch({
      type: POST_EXPERTS_LOGO,
      payload: data.Location,
    });
  } catch (error) {
    dispatch({
      type: EXPERTS_ERROR,
      error,
    });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_EXPERTS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        experts: action.payload,
        loaded: true
      };
    case GET_EXPERT:
      return {
        ...state,
        expert: action.payload,
        loaded: true,
        isPending: false
      }
    case EXPERTS_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: false
      };

    case EXPERTS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        experts: [],
        loaded: true
      };

    case CREATE_EXPERT:
      return {
        ...state,
        isPending: false,
        error: false,
        experts: [...state.experts, action.payload],
        loaded: true
      };

    case EDIT_EXPERT:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        experts: state.experts.map(expert => (expert.objectId == action.payload.id ? action.payload.newExpertData : expert))

      }
    case POST_EXPERTS_LOGO:
      return {
        ...state,
        expertLogo: action.payload,
        isPending: false,
        error: false,
        loaded: true
      }
    case DELETE_EXPERT:
      return {
        ...state,
        isPending: false,
        error: false,
        experts: state.experts.filter((expert) => expert.objectId != action.payload),
        loaded: true
      }
    default:
      return state;
  }
}
