import './AddDemo.css';

import {
  FormControl,
  FormGroup,
  Button,
} from "react-bootstrap";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import {
  getDemo,
  editDemo,
  createDemo
} from "../../reducers/Demos.js";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { stateToHTML } from "draft-js-export-html";
import _ from 'lodash';
const AddDemo = ({
  history,
  isPending,
  demo,
  getDemo,
  editDemo,
  createDemo,

}) => {
  const demoId =
    window.location.hash.includes("editDemo") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log('demoId: ', demoId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [videoTitle, setVideoTitle] = useState('');
  const [videoYoutubeLink, setVideoYoutubeLink] = useState('');
  const [videoDescription, setVideoDescription] = useState('');
  const [solution, setSolution] = useState('');
  const [openSolution, setOpenSolution] = useState(false);
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (demoId) {
      getDemo(demoId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [
    demoId
  ]);

  const cancel = () => {
    history.go(-1);
  }

  const submitDemosPersonDataHandler = (e) => {
    e.preventDefault();
    const newDemoData = {
      videoTitle,
      videoDescription,
      videoYoutubeLink,
      solution
    };

    console.log(newDemoData);

    if (isEditingMode) {
      editDemo(demoId, newDemoData);
    } else if (!isEditingMode && !_.isEmpty(solution)) {
      createDemo(newDemoData);
    } else {
      setError('Solution is required');
      return;
    }
    // wipeAmbassadorImageData();
    history.push("/demos");
  };

  useEffect(() => {
    if (!!demo && demoId) {
      setVideoTitle(demo.videoTitle);
      setVideoYoutubeLink(demo.videoYoutubeLink);
      setVideoDescription(demo.videoDescription);
      setSolution(demo.solution);
      setEdit(true);
    }
  }, [demo]);

  const handleSolutionChange = e => {
    setSolution(e.target.value);
  }

  if (isPending) return <LoadingSpinner />

  return (
    <div>
      <h2 className="title">
        {isEditingMode
          ? `Edit demo: ${videoTitle}`
          : "Add New demo"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitDemosPersonDataHandler}
      >
        <FormGroup>

          <div className="fieldDiv">
            <label id="videoTitle">Video Title</label>
            <FormControl
              type="text"
              value={videoTitle}
              onChange={(e) => setVideoTitle(e.target.value)}
              name="videoTitle"
              htmlFor="videoTitle"
              placeholder="Enter Demo Name"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="videoDescription">Video Description</label>
            <FormControl
              type="text"
              value={videoDescription}
              onChange={(e) => setVideoDescription(e.target.value)}
              name="videoDescription"
              htmlFor="videoDescription"
              placeholder="Enter Position Location"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="videoYoutubeLink">Video YouTube Link</label>
            <FormControl
              type="text"
              value={videoYoutubeLink}
              onChange={(e) => setVideoYoutubeLink(e.target.value)}
              name="videoYoutubeLink"
              htmlFor="videoYoutubeLink"
              placeholder="Enter Video YouTube Link"
              required
            />
          </div>

          <div>
            <div className="fieldDiv">
              <label id="solution">Solution</label>
              <Select
                labelId="solution"
                id="solution"
                open={openSolution}
                onClose={() => setOpenSolution(false)}
                onOpen={() => setOpenSolution(true)}
                value={solution || ''}
                onChange={handleSolutionChange}
                style={{ padding: '5px 10px' }}>
                <MenuItem value="App Boost">
                  App Boost
                </MenuItem>
                <MenuItem value="Retail Gain">
                  Retail Gain
                </MenuItem>
                <MenuItem value="Shopi App">
                  Shopi App
                </MenuItem>
              </Select>
            </div>

          </div>



        </FormGroup>
        <div className="btns">
          <Button className="submitBtn" type="submit" bsStyle="primary">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => ({
  isPending: state.Demos.isPending,
  demo: state.Demos.demo
})

const actions = dispatch => ({
  getDemo: demoId => dispatch(getDemo(demoId)),
  editDemo: (demoId, newDemoData) => dispatch(editDemo(demoId, newDemoData)),
  createDemo: newDemoData => dispatch(createDemo(newDemoData))
})

export default connect(
  mapStateToProps,
  actions
)(AddDemo);
