import './AddGlossary.css';
import {
  FormControl,
  FormGroup,
  Button,
} from "react-bootstrap";


import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import {
  getGlossary,
  createGlossary,
  editGlossary,
} from "../../reducers/Glossary";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { stateToHTML } from "draft-js-export-html";
const AddGlossary = ({
  history,
  isPending,
  glossary,
  getGlossary,
  createGlossary,
  editGlossary

}) => {
  const glossaryId =
    window.location.hash.includes("editGlossary") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log('glossaryId: ', glossaryId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [name, setName] = useState('');
  const [content, setContent] = useState(EditorState.createEmpty());
  const [isContentRequired, setIsContentRequired] = useState(false);
  const [description, setDescription] = useState('');
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState('');


  useEffect(() => {
    if (glossaryId) {
      getGlossary(glossaryId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [
    glossaryId
  ]);

  const cancel = () => {
    history.go(-1);
  }

  const submitGlossaryHandler = (e) => {
    e.preventDefault();
    if (content.getCurrentContent().getPlainText().trim().length <= 0) {
      setIsContentRequired(true);
      return;
    }
    const glossaryData = {
      Name: name,
      Content: stateToHTML(content.getCurrentContent()),
      Description: description,
    };

    if (isEditingMode) {
      editGlossary(glossaryId, glossaryData);
    } else {
      createGlossary(glossaryData);
    }
    // wipeAmbassadorImageData();
    history.push("/Glossary");
  };

  useEffect(() => {
    if (!!glossary && glossaryId) {
      setName(glossary.Name);

      setContent(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              glossary.Content
                ? glossary.Content
                : `<p>No Content</p>`
            )
          )
        )
      );
      setDescription(glossary.Description)
      setEdit(true);


    }
  }, [glossary]);

  if (isPending) return <LoadingSpinner />

  return (
    <div>
      <h2 className="title">
        {isEditingMode
          ? `Edit Glossary: ${name}`
          : "Add New Glossary"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitGlossaryHandler}
      >
        <FormGroup>

          <div className="fieldDiv">
            <label id="glossaryName">Glossary Name</label>
            <FormControl
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              name="glossaryName"
              htmlFor="glossaryName"
              placeholder="Enter Name"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="glossaryDescription">Description</label>
            <FormControl
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              name="glossaryDescription"
              htmlFor="glossaryDescription"
              placeholder="Enter Description"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="glossaryContent">Content</label>
            <Editor
              editorState={content}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setContent(e)}
              onFocus={() => setIsContentRequired(false)}
              onBlur={() =>
                content.getCurrentContent().getPlainText().trim().length <=
                0 && setIsContentRequired(true)
              }
            />

            {isContentRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Content field is required
              </p>
            )}
          </div>
        </FormGroup>

        <div className="btns">
          <Button className="submitBtn" type="submit" bsStyle="primary">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => ({
  isPending: state.Glossary.isPending,
  glossary: state.Glossary.glossary
})

const actions = dispatch => ({
  getGlossary: glossaryId => dispatch(getGlossary(glossaryId)),
  createGlossary: newGlossaryData => dispatch(createGlossary(newGlossaryData)),
  editGlossary: (glossaryId, newGlossaryData) => dispatch(editGlossary(glossaryId, newGlossaryData))
})

export default connect(
  mapStateToProps,
  actions
)(AddGlossary);
