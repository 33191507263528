import './IntegrationForm.scss'
import './AddIntegration.css'
import {
    FormControl,
    FormGroup,
    label,
    Button,
} from "react-bootstrap";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import {
    getIntegration,
    createIntegration,
    editIntegration,
    uploadIntegrationLogo
} from "../../reducers/Integrations";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { stateToHTML } from "draft-js-export-html";
import _ from 'lodash';
const AddIntegration = ({
    history,
    isPending,
    integration,
    getIntegration,
    createIntegration,
    editIntegration,
    uploadLogo,
    integrationLogo

}) => {
    const integrationId =
        window.location.hash.includes("editIntegration") &&
        window.location.hash.split("/")[window.location.hash.split("/").length - 1];
    console.log('integrationId: ', integrationId);
    const [isEditingMode, setIsEditingMode] = useState(false);
    const [name, setName] = useState('');
    const [type, setType] = useState('');
    const [openType, setOpenType] = useState('');
    const [logo, setLogo] = useState('');
    const [description, setDescription] = useState(EditorState.createEmpty());
    const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
    const [edit, setEdit] = useState(false);
    const [error, setError] = useState('');
    const [selectedLogo, setSelectedLogo] = useState('');
    const [selectedLogoUrl, setSelectedLogoUrl] = useState('');
    const [show, setShow] = useState(false);


    useEffect(() => {
        if (integrationId) {
            getIntegration(integrationId);
            setIsEditingMode(true);
        } else {
            setIsEditingMode(false);
        }
    }, [
        integrationId
    ]);

    const cancel = () => {
        history.go(-1);
    }

    useEffect(() => {
        if (selectedLogo) {
            if (selectedLogo.size > 512000) {
                setShow(true);
                selectedLogo("");
            }
        }
        if (integrationLogo) {
            console.log('integrationLogo: ', integrationLogo);
            setSelectedLogoUrl(integrationLogo);
        }
    }, [selectedLogo, integrationLogo]);


    const storeImageHandler = (e) => {
        e.preventDefault();
        setSelectedLogo(e.target.files[0]);
    };



    const submitIntegrationHandler = (e) => {
        e.preventDefault();
        if (description.getCurrentContent().getPlainText().trim().length <= 0) {
            setIsDescriptionRequired(true);
            return;
        }
        const integrationData = {
            Name: name,
            Type: type,
            Description: stateToHTML(description.getCurrentContent()),
            Logo: selectedLogoUrl
        };

        if (isEditingMode) {
            editIntegration(integrationId, integrationData);
        } else if (!isEditingMode && !_.isEmpty(type)) {
            createIntegration(integrationData);
        } else {
            setError('Integration Type is required');
            return;
        }

        uploadLogo(selectedLogo, name);

        // wipeAmbassadorImageData();
        history.push("/Integrations");
    };

    useEffect(() => {
        if (!!integration && integrationId) {
            setType(integration.Type);
            setName(integration.Name);

            setDescription(
                EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                        convertFromHTML(
                            integration.Description
                                ? integration.Description
                                : `<p>No Content</p>`
                        )
                    )
                )
            );
            setSelectedLogoUrl(integration.Logo);
            setEdit(true);


        }
    }, [integration, integrationId]);

    const handleTypeChange = e => {
        setType(e.target.value);
    }

    const uploadLogoHandler = () => {
        uploadLogo(selectedLogo, name);
        setEdit(true);
    };

    if (isPending) return <LoadingSpinner />

    return (
        <div>
            <h2 className="title">
                {isEditingMode
                    ? `Edit Integration: ${name}`
                    : "Add New Integration"}
            </h2>
            <form
                className="container"
                style={{ minHeight: "auto" }}
                onSubmit={submitIntegrationHandler}
            >
                <FormGroup>

                    <div className="fieldDiv">
                        <label id="integrationName">Integration Name</label>
                        <FormControl
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            name="integrationName"
                            htmlFor="integrationName"
                            placeholder="Enter Name"
                            required
                        />
                    </div>
                    <div className="fieldDiv">
                        <label id="type">Type</label>
                        <Select
                            labelId="type"
                            id="type"
                            open={openType}
                            onClose={() => setOpenType(false)}
                            onOpen={() => setOpenType(true)}
                            value={type || ''}
                            onChange={handleTypeChange}
                            style={{ padding: '5px 10px' }}>
                            <MenuItem value="sms">
                                SMS/Whatsapp Providers
                            </MenuItem>
                            <MenuItem value="email">
                                Email Providers
                            </MenuItem>
                            <MenuItem value="analytics">
                                Analytics Providers
                            </MenuItem>
                            <MenuItem value="mmps">
                                MMPs
                            </MenuItem>
                            <MenuItem value="dataExchange">
                                Data Exchange
                            </MenuItem>
                            <MenuItem value="dataDashboards">
                                Data Dashboards
                            </MenuItem>
                            <MenuItem value="ad">
                                Ad Networks
                            </MenuItem>
                        </Select>
                    </div>
                    <div className="fieldDiv">
                        <label id="integrationDescription">Description</label>
                        <Editor
                            editorState={description}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={(e) => setDescription(e)}
                            onFocus={() => setIsDescriptionRequired(false)}
                            onBlur={() =>
                                description.getCurrentContent().getPlainText().trim().length <=
                                0 && setIsDescriptionRequired(true)
                            }
                        />

                        {isDescriptionRequired && (
                            <p
                                style={{
                                    color: "red",
                                    textAlign: "center",
                                    fontWeight: 700,
                                }}
                            >
                                Content field is required
                            </p>
                        )}
                    </div>
                    <div className="fieldDiv">
                        <label>
                            {isEditingMode ? "Change" : "Upload"} Integration Logo
                        </label>
                        <div className="file-input">
                            {/* SVG support */}
                            <input
                                type="file"
                                accept="image/*"
                                placeholder="Choose Integration Logo"
                                onChange={storeImageHandler}
                                className="file"
                                id="file"
                            />
                            <label htmlFor="file">
                                {selectedLogo || isEditingMode ? "Change " : "Select "} Logo
                            </label>
                        </div>
                        <div style={{ marginTop: '20px' }}>
                            {selectedLogo && (
                                <React.Fragment>
                                    <p>Please Upload the logo</p>
                                    <button
                                        onClick={uploadLogoHandler}
                                        className="btn btn-success w-100 my-3"
                                    >
                                        Upload logo
                                    </button>
                                </React.Fragment>
                            )}
                        </div>

                    </div>

                </FormGroup>
                <div className="btns">
                    <Button disabled={!edit} className="submitBtn" type="submit" bsStyle="primary">
                        {isEditingMode ? "Edit" : "Submit"}
                    </Button>
                    <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
                        Cancel
                    </Button>
                </div>
                {show && (
                    <ConfirmModal
                        handleClose={() => setShow(false)}
                        handleShow={() => setShow(true)}
                        message={`Oops! Image size is more than 500 kilobyte! Please Choose smaller size image`}
                        setShow={setShow}
                        show={show}
                    ></ConfirmModal>
                )}
                <div className="error">
                    <p>{error}</p>
                </div>
            </form>
        </div>
    )
}

const mapStateToProps = state => ({
    isPending: state.Integrations.isPending,
    integration: state.Integrations.integration,
    integrationLogo: state.Integrations.integrationLogo
})

const actions = dispatch => ({
    getIntegration: integrationId => dispatch(getIntegration(integrationId)),
    createIntegration: newIntegrationData => dispatch(createIntegration(newIntegrationData)),
    editIntegration: (integrationId, newIntegrationData) => dispatch(editIntegration(integrationId, newIntegrationData)),
    uploadLogo: (image, id) => dispatch(uploadIntegrationLogo(image, id))
})

export default connect(
    mapStateToProps,
    actions
)(AddIntegration);
