import {
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import moment from "moment";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deletePlan, editPlan, getPlans } from "../../reducers/Plans";
import "./Plans.css";
const tableHead = [
  "Name",
  "project type",
  "status",
  "interval",
  "price",
  "offer values",
  "trial days",
  "created at",
  "action",
];
const byOffersValues = [
  "all",
  "marketing links",
  "app boost",
  "smart retargeting",
  "app backend",
  "mobile attribution",
];

const Plans = ({ plans, getPlans, history, deletePlan }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("all");


  useEffect(() => {
    getPlans();
  }, []);
  const deactivePlan = (id) => {
    deletePlan(id).then(() => {
      setOpen(true);
    });
    getPlans();
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  let sortedPlan = [];
  if (plans) {
    sortedPlan = plans.sort((a, b) => (a.status > b.status ? 1 : -1));
  }
  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "offers_type") setValue(value);
  };
  if (plans && value === "all") {
    sortedPlan = plans.sort((a, b) => (a.status > b.status ? 1 : -1));
  } else {
    sortedPlan = sortedPlan.filter((plan) => plan.offers_values === value);
  }

  console.log(sortedPlan, "sortedPlan");
  return (
    <React.Fragment>
      <h3 style={{ margin: "20px 21px 10px" }}>
        Select plan or add new one
        <i
          class="fa fa-plus-square"
          aria-hidden="true"
          onClick={() => {
            history.push("addPlan");
          }}
        ></i>
      </h3>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          plan Not active now
        </Alert>
      </Snackbar>
      <div className="selectContainer">
        <div>
          <span>select by offer values</span>
          <select
            name="offers_type"
            className="select"
            required
            value={value}
            onChange={(e) => handleChange(e)}
          >
            {byOffersValues.map((option, index) => {
              return (
                <option value={option} key={index}>
                  {option}
                </option>
              );
            })}
          </select>
        </div>
       
      </div>
      <Table>
        <TableHead className="tableHead">
          {tableHead.map((title, index) => {
            return <TableCell key={index}>{title}</TableCell>;
          })}
        </TableHead>
        <TableBody>
          {plans &&
            sortedPlan.map((plan, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell>{plan.name}</TableCell>
                  <TableCell>{plan.project_type}</TableCell>
                  <TableCell>{plan.status}</TableCell>
                  <TableCell>{plan.interval}</TableCell>
                  <TableCell>{plan.price}</TableCell>
                  <TableCell>{plan.offers_values}</TableCell>

                  <TableCell>{plan.trial_period_days}</TableCell>

                  <TableCell>
                    {moment(plan.created_at).format("MMM Do YY")}
                  </TableCell>
                  <TableCell>
                    <button
                      className="actions"
                      onClick={() => {
                        history.push(`editPlan/${plan.id}`);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      className="actions"
                      onClick={() => {
                        deactivePlan(plan.id);
                      }}
                    >
                      deactive
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
export default connect(
  (state) => ({
    plans: state.Plans.plans.plans,
    isPending: state.Plans.isPending,
  }),
  (dispatch) => ({
    getPlans: () => dispatch(getPlans()),
    deletePlan: (id) => dispatch(deletePlan(id)),
  })
)(Plans);
