import React, { useState } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import { setMobileNavVisibility } from "../../reducers/Layout";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import SideBar from "../../components/SideBar";
import Auth from "../Auth";
/**
 * Pages
 */
import Dashboard from "../Dashboard";

import Glossary from "../Glossary";
import Integrations from "../Integrations";
import AddIntegration from "../Integrations/AddIntegration";

import Careers from "../Careers/Careers";
import DemoCenter from "../DemoCenter";
import AddDemo from "../DemoCenter/AddDemo";
import Experts from "../Experts";
import AddExpert from "../Experts/AddExpert";
import Customers from "../Customers";
import AddCustomer from "../Customers/AddCustomer";
import Testimonials from "../Testimonials";
import AddTestimonial from "../Testimonials/AddTestimonial";
import AddGlossary from "../Glossary/AddGlossary";
import AddCareer from "../Careers/AddCareer";
import Teams from "../Teams";
import AddTeams from "../Teams/AddTeams";
import UseCases from "../UseCases";
import AddUseCases from "../UseCases/AddUseCases";
import Notifications from "../Notification";
import AddNotification from "../Notification/AddNotification";
import Ebooks from "../EBooks";
import AddEbook from "../EBooks/AddEbook";
import Plans from "../Plans";
import AddPlans from "../Plans/AddPlans";
import ScheduledJobs from "../ScheduledJobs";
import "antd/dist/antd.css";
import { ToastContainer } from "react-toastify";
import {AiOutlineMenu} from "react-icons/ai";
import {IoMdClose} from "react-icons/io"
import ListSuits from "../ListSuits";
const Main = ({ mobileNavVisibility, hideMobileMenu, history, isLoggedIn }) => {
  history.listen(() => {
    if (mobileNavVisibility === true) {
      hideMobileMenu();
    }
  });
  const [mobileMenu, setMobileMenu] = useState(false);

  const userlogin = sessionStorage.getItem("user");
  return (
    <div
      // className={cx({
      //   "nav-open": mobileNavVisibility === true,
      // })}
    >
      {!isLoggedIn && !userlogin ? (
        <Auth />
      ) : (
        <div className="wrapper">
          {/* <div className="close-layer" onClick={hideMobileMenu}></div> */}
          <button className="menuBtn">

            {!mobileMenu ? (
              <AiOutlineMenu
                onClick={() => {
                  setMobileMenu(true);
                }}
              />
            ) : (
              <IoMdClose
                onClick={() => {
                  setMobileMenu(false);
                }}
              />
            )}

         
          </button>
          <SideBar mobileMenu={mobileMenu}/>

          <div className="main-panel">
            <Header />
            <Switch>
              <Route path="/glossary" component={Glossary} />
              <Route path="/integrations" component={Integrations} />
              <Route
                path={["/addIntegration", "/editIntegration/:id"]}
                component={AddIntegration}
              />
              <Route path="/careers" component={Careers} />
              <Route
                path={["/addCareer", "/editCareer/:id"]}
                component={AddCareer}
              />
              <Route
                path={["/addGlossary", "/editGlossary/:id"]}
                component={AddGlossary}
              />
              <Route path="/demos" component={DemoCenter} />
              <Route path={["/addDemo", "/editDemo/:id"]} component={AddDemo} />
              <Route path="/experts" component={Experts} />
              <Route
                path={["/addExpert", "/editExpert"]}
                component={AddExpert}
              />
              <Route path="/customers" component={Customers} />
              <Route
                path={["/addCustomer", "/editCustomer"]}
                component={AddCustomer}
              />
              <Route path="/testimonials" component={Testimonials} />
              <Route
                path={["/addTestimonial", "/editTestimonial/:id"]}
                component={AddTestimonial}
              />

              <Route path="/teams" component={Teams} />
              <Route
                path={["/addTeams", "/editTeam/:id"]}
                component={AddTeams}
              />

              <Route path="/useCases" component={UseCases} />
              <Route
                path={["/addUseCase", "/editUseCase/:id"]}
                component={AddUseCases}
              />
              <Route path="/notifications" component={Notifications} />
              <Route path="/ebooks" component={Ebooks} />
              <Route
                path={["/addEbook", "/editEbook/:id"]}
                component={AddEbook}
              />
              <Route path="/plans" component={Plans} />
              <Route
                path={["/addPlan", "/editPlan/:id"]}
                component={AddPlans}
              />
              <Route path="/scheduledJobs" component={ScheduledJobs} />
              <Route path="/suits" component={ListSuits} />

              <Route
                path={["/addNotification", "/editNotification/:id"]}
                component={AddNotification}
              />

              <Route exact path="/" component={Dashboard} />
              {/* <Route path="/components" component={Components} /> */}
            </Switch>
            <Footer />
          </div>
        </div>
      )}
      {/* {isLoggedIn || userlogin && ( */}

      {/* )} */}

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
    </div>
  );
};

const mapStateToProp = (state) => ({
  mobileNavVisibility: state.Layout.mobileNavVisibility,
  isLoggedIn: state.Auth.isLoggedIn,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
});

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(Main));
