import { scheduledApi, getConfigHeaders, getConfigHeadersJop,  } from "../api";

const defaultState = {
  jobs: [],
  job: {},
  error: null,
  isPending: null,
  loaded: false,
  msg: "",
};

const EDIT_JOB = "EDIT_JOB";
const CREATE_JOB = "CREATE_JOB";
const DELETE_JOB = "DELETE_JOB";
const LOAD_JOBS_LIST = "LOAD_JOBS_LIST";
const LOAD_JOB = "LOAD_JOB";

const GET_JOB = "GET_JOB";
const JOPS_PENDING = "JOPS_PENDING";
const JOPS_ERROR = "JOPS_ERROR";

// all ScheduledS
export const getScheduleds = (url) => async (dispatch, getState) => {
  dispatch({ type: JOPS_PENDING });
  try {
 
    const data = await scheduledApi(
      `${url}`,
      "get",
      getConfigHeadersJop()
    );
    console.log(data,'data');
    dispatch({
      type: LOAD_JOBS_LIST,
      payload: data.jobs,
    });
  } catch (error) {
    dispatch({
      type: JOPS_ERROR,
      error,
    });
  }
};
//  Scheduled
export const getScheduled = (id) => async (dispatch, getState) => {
  dispatch({ type: JOPS_PENDING });
  try {
 
    const data = await scheduledApi(
      `job/${id}`,
      "get",
      getConfigHeadersJop()
    );
    console.log(data,'data');
    dispatch({
      type: LOAD_JOB,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: JOPS_ERROR,
      error,
    });
  }
};

// EDIT Scheduled
export const startScheduled = (id,editScheduledData) => async (dispatch, getState) => {
  dispatch({ type: JOPS_PENDING });
  try {
    await scheduledApi(
      `job/${id}/start`,
      "put",
      editScheduledData,
      getConfigHeadersJop(),
    );
    dispatch({
      type: EDIT_JOB,
      payload: { id },
    });
  } catch (error) {
    dispatch({ type: JOPS_ERROR, error });
  }
};

// DEACTIVE
export const deleteScheduled = (id) => async (dispatch, getState) => {
  dispatch({ type: JOPS_PENDING });
  try {
    await scheduledApi(`job/${id}`, "delete", getConfigHeadersJop());
    dispatch({
      type: DELETE_JOB,
      payload: id,
    });
  } catch (error) {
    dispatch({ type: JOPS_ERROR, error });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LOAD_JOBS_LIST:
      return {
        ...state,
        isPending: false,
        error: false,
        jobs: action.payload,
        loaded: true,
      };
      case LOAD_JOB:
        return {
          ...state,
          isPending: false,
          error: false,
          job: action.payload,
          loaded: true,
        };
    case GET_JOB:
      return {
        ...state,
        job: action.payload,
        loaded: true,
        isPending: false,
      };
    case JOPS_PENDING:
      return {
        ...state,
        isPending: true,
        error: false,
        loaded: false,
      };

    case JOPS_ERROR:
      return {
        ...state,
        isPending: false,
        error: action.error,
        jops: [],
        loaded: true,
      };

    case CREATE_JOB:
      return {
        ...state,
        isPending: false,
        error: false,
        jobs: [...state.jobs, action.payload],
        loaded: true,
      };

    case EDIT_JOB:
      return {
        ...state,
        isPending: false,
        error: false,
        loaded: true,
        jobs: state.jobs.map((job) =>
          job.id == action.payload.id ? action.payload.newScheduledData : job
        ),
      };

    case DELETE_JOB:
      return {
        ...state,
        isPending: false,
        error: false,
        jobs: state.jobs.filter((job) => job.id != action.payload),
        loaded: true,
      };
    default:
      return state;
  }
}
