import axios from "axios";
import { config, getServerUrl } from "./config";

export const appBackendAppgainUrl = async (endpoint, method, body, headers) => {
  const url = `https://appbackend.appgain.io/5864ef5223fcb95c7a99a156/central/classes/${endpoint}`;
  try {
    const { data } = await axios[method](url, body, headers);
    console.log(data);
    return data;
  } catch (error) {
    return error;
  }
};
export const appgainApi = async (endpoint, method, body, headers) => {
  const url = `https://adminapi.appgain.io/${endpoint}`;
  try {
    const { data } = await axios[method](url, body, headers);

    return data;
  } catch (error) {
    return error;
  }
};
export const scheduledApi = async (endpoint, method, body, headers) => {
  const url = `https://sechulder.appgain.io/${endpoint}`;
  try {
    const { data } = await axios[method](url, body, headers);

    return data;
  } catch (error) {
    return error;
  }
};
export const getConfigHeaders = () => {
  const config = {
    headers: {},
  };
  config.headers["Authorization"] =
    "Basic " + btoa("apps@appgain.io" + ":" + config.kongPassword);
  config.headers["Content-Type"] = "application/json";
  config.headers["X-Parse-Application-Id"] = "central"
  // process.env.APPLICATIONID.replace("'", "");
  config.headers["X-Parse-Master-Key"] =
    "MASTER-7f716ea8c7f86ecf40cbe1635c6d177443b352ad22292100d92799dfd7fd8938";
  return config;
};
export const getConfigHeadersJop=()=>{
  const config = {
    headers: {},
  };
  config.headers["appapikey"] =
  "184086a29c8a567a53385ad6e0dfc3a40056ff7b8270c17fe4d302485157bf95";
// 


  return config;
}
export const getConfigHeadersApi = () => {
  const config = {
    headers: {},
  };
  config.headers["appapikey"] =
    "af3ec344b3f4a10aebd37c44232543977917233520634b8957bcff4640e3b0f9";
  // config.headers["Authorization"] =
  //   "Basic " + btoa("testers+2022@appgain.io" + ":" + "123456789");
  config.headers["Content-Type"] = "application/json";
  config.headers["X-Parse-Application-Id"] = "central"
  // // process.env.APPLICATIONID.replace("'", "");
  // config.headers["X-Parse-Master-Key"] =
  //   "MASTER-7f716ea8c7f86ecf40cbe1635c6d177443b352ad22292100d92799dfd7fd8938";
  return config;
};

export const fireAutomator = async (
  projectId,
  triggerPoint,
  userId,
  username,
  apiKey
) => {
  const url = `https://automator.appgain.io/automessages/${projectId}/firevent/${triggerPoint}/${userId}?applicantName=${username}`;
  const config = {
    headers: {
      appApiKey: apiKey,
    },
  };
  try {
    const { data } = await axios.get(url, config);
    return data;
  } catch (error) {
    return error;
  }
};

const networkService = {
  service: this,
  // serverUrl: "https://api2.ikhair.net",
  kongPassword: config.kongPassword,
  apiPassword: config.apiPassword,
  serverUrl: getServerUrl(),
  services: [
    {
      name: "glossary",
      url: "/Glossary/",
      method: "GET",
    },
    {
      name: "listIntegrations",
      url: "/integrations",
      method: "GET",
    },
    {
      name: "activities",
      url: "/activities",
      method: "GET",
    },
    {
      name: "mnos",
      url: "/dashboard_mnos",
      method: "GET",
    },
    {
      name: "projects",
      url: "/projects",
      method: "GET",
    },
    {
      name: "cases",
      url: "/causes",
      method: "GET",
    },
    {
      name: "case",
      url: "/causes/",
      method: "POST",
    },
    {
      name: "projectStatus",
      url: "/projects/{id}?edit_status=true",
      method: "PUT",
    },
    {
      name: "editIntegration",
      url: "/integrations/{id}",
      method: "PUT",
    },
    {
      name: "editProject",
      url: "/projects/{id}",
      method: "PUT",
    },
    {
      name: "getIntegration",
      url: "/integrations/{id}",
      method: "GET",
    },
    {
      name: "createIntegration",
      url: "/integrations",
      method: "POST",
    },
    {
      name: "deleteIntegration",
      url: "/integrations/{id}",
      method: "DELETE",
    },
    {
      name: "getProject",
      url: "/projects/{id}",
      method: "GET",
    },
    {
      name: "updateProjects",
      url: "/ikhair/update-projects/",
      method: "PUT",
    },
    {
      name: "updateSMSPayment",
      url: "/sms_payment/{id}",
      method: "PUT",
    },
    {
      name: "updateUrgentCase",
      url: "/causes/{id}",
      method: "PUT",
    },
    {
      name: "banners",
      url: "/banners",
      method: "GET",
    },
    {
      name: "getBanner",
      url: "/banners/{id}",
      method: "GET",
    },
    {
      name: "editBanner",
      url: "/banners/{id}",
      method: "PUT",
    },
    {
      name: "deleteBanner",
      url: "/banners/{id}",
      method: "DELETE",
    },
    {
      name: "bannerStatus",
      url: "/banners/{id}?edit_status=true",
      method: "PUT",
    },
    {
      name: "createBanner",
      url: "/banners",
      method: "POST",
    },
    {
      name: "getReport",
      url: "/takamul/{orgID}?from_date={startDate}&to_date={endDate}",
      method: "GET",
    },
    {
      name: "buildReports",
      url: "/takamul/{orgID}?from_date={startDate}&to_date={endDate}",
      method: "PUT",
    },
    {
      name: "keywordReport",
      url: "/takamul/{orgID}/{keyword}?from_date={startDate}&to_date={endDate}&json=true",
      method: "GET",
    },
    { name: "fileUpload", url: "files/", method: "POST" },
    {
      name: "createOrg",
      url: "/organizations",
      method: "POST",
    },
    {
      name: "orgStatus",
      url: "/organizations/{id}?edit_status=true",
      method: "PUT",
    },
    {
      name: "editOrg",
      url: "/organizations/{id}",
      method: "PUT",
    },
    {
      name: "deleteOrg",
      url: "/organizations/{id}",
      method: "DELETE",
    },
    {
      name: "getSingleOrg",
      url: "/organizations/{id}",
      method: "GET",
    },
    {
      name: "createActivity",
      url: "/activities",
      method: "POST",
    },
    {
      name: "activityStatus",
      url: "/activities/{id}?edit_status=true",
      method: "PUT",
    },
    {
      name: "editActivity",
      url: "/activities/{id}",
      method: "PUT",
    },
    {
      name: "deleteActivity",
      url: "/activities/{id}",
      method: "DELETE",
    },
    {
      name: "getSingleActivity",
      url: "/activities/{id}",
      method: "GET",
    },
  ],
  getService: function (name) {
    for (let service of this.services)
      if (service.name === name) return service;
  },
  send: function (name, data) {
    let service = { ...this.getService(name) };
    if (name === "fileUpload") {
      service.url += data.name;
    }

    if (service.url && service.url.includes("{")) {
      for (let attr in data) {
        let search = "{" + attr + "}";
        if (service.url.includes(search)) {
          service.url = service.url.replace(search, data[attr]);
          delete data[attr];
        }
      }
    }
    let query = "";
    console.log(data, service.url);
    if (
      service.method == "GET" &&
      name !== "getReport" &&
      name !== "keywordReport"
    ) {
      query = "?";
      for (let key in data) {
        query += `${key}=${data[key]}&`;
        delete data[key];
      }
      if (query.includes("&")) query = query.slice(0, -1);
    }

    let request = {
      method: service.method,
      url: this.serverUrl + service.url + query,
    };
    if (data) request.data = data;

    request.headers = {};
    request.headers["Authorization"] =
      "Basic " + btoa("apps@appgain.io" + ":" + this.kongPassword);
    request.headers["Content-Type"] = "application/json";
    request.headers["X-Parse-Application-Id"] =
      process.env.APPLICATIONID.replace("'", "");
    request.headers["X-Parse-Master-Key"] =
      "MASTER-d717999230ef1b7eca12db16650a0bd33153024a12965311faf6dd660521a59b";

    if (name === "fileUpload") {
      request.data = data.data;
      request.headers["Content-Type"] = "image";
    }
    if (name === "createBanner" || name === "editBanner") {
      console.log(request);
      request.headers["Content-Type"] = "application/json";
    }

    console.log(request, "request");
    return axios(request);
  },
};

export default networkService;
