import "./Auth.css";

import React, { useState } from "react";

import { connect } from "react-redux";
import { checkUser } from "../../reducers/Auth";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Typography } from "antd";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import toaster from "../../modules/toaster";

const Auth = ({ checkUser, user,error, isPending }) => {
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const handleLogin = async (username, pass, e) => {
    e.preventDefault();
    try {
      const response = await checkUser(username, pass);
      localStorage.setItem("user", response.username);
      sessionStorage.setItem("user", response.username);

      toaster.success(`Welcome ${response.username}`);
    } catch (e) {
      console.error(e?.message || e || error);
      toaster.error(e?.message || e || error);
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Container>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
        onSubmit={(e) => handleLogin(username, pass, e)}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "500px",
            boxShadow: "0 0 10px rgba(0,0,0,0.5)",
            borderRadius: "5px",
            padding: "20px",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
            justifyContent: "center",
            gap: "20px",
            position: "relative",
          }}
        >
          {isPending && (
            <Box
              sx={{
                position: "absolute",
                inset: 0,
                backgroundColor: "rgba(0,0,0,0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1,
              }}
            >
              <CircularProgress />
            </Box>
          )}

          <Typography variant="h6" align="center" gutterBottom>
            Please Login to continue
          </Typography>
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            name="username"
            placeholder="Enter Username"
            required
            variant="outlined"
          />

          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            value={pass}
            onChange={(e) => setPass(e.target.value)}
            name="password"
            placeholder="Enter Password"
            required
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="btns">
            <Button className="submitBtn" variant="outlined" type="submit">
              Login
            </Button>
          </div>
        </Box>
      </form>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Auth.isPending,
  error: state.Auth.error,
  user: state.Auth.user,
  isLogged: state.Auth.isLogged,
});

const actions = (dispatch) => ({
  checkUser: (username, email, pass) =>
    dispatch(checkUser(username, email, pass)),
});

export default connect(mapStateToProps, actions)(Auth);
