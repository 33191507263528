import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  getDemos,
  deleteDemo,
} from "../../reducers/Demos";
import DemosDataTable from '../../components/DemosDataTable';
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import _ from 'lodash';
import './Demos.css';

const DemoCenter = ({
  demos,
  getDemos,
  deleteDemo,
  isPending,
  history,
}) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    getDemos();
  }, []);

  useEffect(() => {
    setData(demos);
  }, [demos])

  const handleAddCase = () => {
    history.push('addDemo');
  }
 
  function reload(){

    try {
      
        fetch(
        'https://api.vercel.com/v1/integrations/deploy/prj_2avFJzRCmNi71mYejKrW91VtgFAa/j71wYLiEWQ',{
        method:'GET',
       headers: {
          'Content-Type': 'application/json',
        },
       })
       alert('publishing done successfully')

      } catch (error) {
        console.log("error", error);
        alert('failed')

    }
  }

  if (isPending) {
    return <LoadingSpinner />;
  }
  console.log('demos: ', demos);
  return (
    <div className="card">
      <div className="header">
        <h4 className="title">
          Demos
          <button
            onClick={reload}
            className="btn btn-primary addProjectBtn me-2"
          >
            publish to the website
          </button>

          <button
            onClick={handleAddCase}
            className="btn btn-primary addProjectBtn"
          >
            <i className="fa fa-plus"></i> Add a Demo
          </button>
        </h4>
      </div>
      <div
        className="content table-responsive table-full-width"
        style={{ width: "100%" }}
      >
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>Video Title</th>
              <th>Video Description</th>
              <th>Video YouTube Link</th>
              <th>Solution</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) && data.map((demo) => (
              <DemosDataTable
                key={demo.objectId}
                demo={demo}
                isPending={isPending}
                deleteDemo={deleteDemo}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    demos: state.Demos.demos,
    isPending: state.Demos.isPending,
  }),
  (dispatch) => ({
    getDemos: () => dispatch(getDemos()),
    deleteDemo: (id) => dispatch(deleteDemo(id)),
  })
)(DemoCenter);
