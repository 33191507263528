import { appBackendAppgainUrl, getConfigHeaders } from "../api";
import axios from 'axios';

const url = `Glossary`;
const configUrl = `configs`

const defaultState = {
  glossaries: [],
  glosary: {},
  glossaryImage: '',
  glossariesConfig: {},
  glossary: null,
  error: null,
  isPending: null,
  loaded: false,
};

const APPROVE_GLOSSARY = "APPROVE_GLOSSARY";
const GET_GLOSSARIES = "GET_GLOSSARIESIES";
const DELETE_GLOSSARY = "DELETE_GLOSSARY";
const GLOSSARIES_PENDING = "GLOSSARIES_PENDING";
const GLOSSARY_PENDING = "GLOSSARY_PENDING";
const GET_GLOSSARY = "GET_GLOSSARY";
const GLOSSARY_ERROR = "GLOSSARY_ERROR";
const GLOSSARIES_ERROR = "GLOSSARIES_ERROR";
const EDIT_GLOSSARY = "EDIT_GLOSSARY";
const POST_GLOSSARYIMAGE = "POST_GLOSSARYIMAGE";
const CREATE_GLOSSARY = "CREATE_GLOSSARY";
const GLOSSARY_CONFIG = "GLOSSARY_CONFIG";
const GLOSSARY_CONFIG_ERROR = "GLOSSARY_CONFIG_ERROR";


// GET inMemory Persons
export const getGlossaries = () => async (dispatch, getState) => {
  dispatch({
    type: GLOSSARIES_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}?limit=1000`,
      "get",
      getConfigHeaders()
    );
    console.log(getState().InMemory);
    dispatch({
      type: GET_GLOSSARIES,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: GLOSSARIES_ERROR,
      payload: error,
    });
  }
};

// GET Glossary

export const getGlossary = (glossaryId) => async (dispatch, getState) => {
  dispatch({
    type: GLOSSARY_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${glossaryId}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_GLOSSARY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GLOSSARY_ERROR,
      payload: error,
    });
  }
};

// Create glossary
export const createGlossary = (newGlossaryData) => async (dispatch, getState) => {
  dispatch({
    type: GLOSSARIES_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "post",
      newGlossaryData,
      getConfigHeaders()
    );
    console.log(getState().InMemory);
    dispatch({
      type: CREATE_GLOSSARY,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GLOSSARIES_ERROR,
      payload: error,
    });
  }
};

export const editGlossary = (id, newGlossaryData) => async (dispatch) => {
  dispatch({
    type: GLOSSARY_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newGlossaryData,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_GLOSSARY,
      payload: { id, newGlossaryData },
    });
  } catch (error) {
    dispatch({
      type: GLOSSARY_ERROR,
      payload: error,
    });
  }
}

// Delete Glossary
export const deleteGlossary = (id) => async (dispatch, getState) => {
  dispatch({
    type: GLOSSARIES_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());

    dispatch({
      type: DELETE_GLOSSARY,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: GLOSSARIES_ERROR,
      payload: error,
    });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case GLOSSARY_PENDING:
    case GLOSSARIES_PENDING:
      return { ...state, isPending: true };

    case GET_GLOSSARIES:
      return {
        ...state,
        glossaries: action.payload,
        loaded: true,
        isPending: false,
      };

    case CREATE_GLOSSARY:
      return {
        glossaries: [...state.glossaries, action.payload],
        isPending: false,
        loaded: true
      }

    case EDIT_GLOSSARY:
      return {
        ...state,
        glossaries: state.glossaries.map((glossary) => (glossary.objectId === action.payload.id
          ? action.payload.newGlossaryData : glossary)),
        isPending: false,
        loaded: true
      };

    case POST_GLOSSARYIMAGE:
      return {
        ...state,
        glossaryImage: action.payload,
        isPending: false,
        loaded: true,
      };

    case GET_GLOSSARY:
      return {
        ...state,
        glossary: action.payload,
        loaded: true,
        isPending: false
      };


    case GLOSSARY_CONFIG: {
      return { ...state, glossariesConfig: action.payload }
    };

    case DELETE_GLOSSARY:
      return {
        ...state,
        glossaries: state.glossaries.filter(
          (person) => person.objectId !== action.payload
        ),
        loaded: true,
        isPending: false,
      };
    default:
      return { ...state };
  }
}
