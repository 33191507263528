import React, { useEffect, useState } from "react";
import "./Experts.css";
import { connect } from "react-redux";
import {
  getExperts,
  deleteExpert
} from "../../reducers/Experts";
import ExpertsDataTable from "../../components/ExpertsDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import _ from 'lodash';

const Experts = ({
  experts,
  getExperts,
  deleteExpert,
  isPending,
  history,
}) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    getExperts();
  }, []);

  useEffect(() => {
    setData(experts)
  }, [experts]);

  const handleAddExpert = () => {
    history.push('addExpert');
  }

  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <div className="card">
      <div className="header">
        <h4 className="title">
          Experts
          <button
            onClick={handleAddExpert}
            className="btn btn-primary addProjectBtn"
          >
            <i className="fa fa-plus"></i> Add an Expert
          </button>
        </h4>
      </div>
      <div
        className="content table-responsive table-full-width"
        style={{ width: "100%" }}
      >
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>Logo</th>
              <th>Name</th>
              <th>Location</th>
              <th>Services</th>
              <th>Description</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) && data.map((expert) => (
              <ExpertsDataTable
                key={expert.objectId}
                expert={expert}
                isPending={isPending}
                deleteExpert={deleteExpert}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    experts: state.Experts.experts,
    isPending: state.Experts.isPending,
    expert: state.Experts.expert,
  }),
  (dispatch) => ({
    getExperts: () => dispatch(getExperts()),
    deleteExpert: id => dispatch(deleteExpert(id))
  })
)(Experts);
