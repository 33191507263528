import React, { useEffect, useState } from "react";
import "./Teams.css";
import { connect } from "react-redux";
import { getTeams, deleteTeam } from "../../reducers/Teams";
import TeamsDataTable from "../../components/TeamsDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import _ from "lodash";

const Teams = ({ teams, getTeams, deleteTeam, isPending, history }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getTeams();
  }, []);

  useEffect(() => {
    setData(teams);
    console.log("teams: ", teams);
  }, [teams]);

  const handleAddTeam = () => {
    history.push("addTeams");
  };

  function reload(){

    try {
      
        fetch(
        'https://api.vercel.com/v1/integrations/deploy/prj_2avFJzRCmNi71mYejKrW91VtgFAa/j71wYLiEWQ',{
        method:'GET',
       headers: {
          'Content-Type': 'application/json',
        },
       })
       alert('publishing done successfully')

      } catch (error) {
        console.log("error", error);
        alert('failed')

    }
  }
  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <div className='card'>
      <div className='header'>
        <h4 className='title'>
          Teams
          <button
            onClick={reload}
            className="btn btn-primary addProjectBtn me-2"
          >
            publish to the website
          </button> 

          <button
            onClick={handleAddTeam}
            className='btn btn-primary addProjectBtn'
          >
            <i className='fa fa-plus'></i> Add a Team
          </button>
        </h4>
      </div>
      <div
        className='content table-responsive table-full-width'
        style={{ width: "100%" }}
      >
        <table className='table table-hover table-striped'>
          <thead>
            <tr>
              <th>Photo</th>
              <th>Name</th>
              <th>Title</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) &&
              data.map(team => (
                <TeamsDataTable
                  key={team.objectId}
                  team={team}
                  isPending={isPending}
                  deleteTeam={deleteTeam}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    teams: state.Teams.teams,
    isPending: state.Teams.isPending,
    team: state.Teams.team,
  }),
  dispatch => ({
    getTeams: () => dispatch(getTeams()),
    deleteTeam: id => dispatch(deleteTeam(id)),
  })
)(Teams);
