import { appBackendAppgainUrl, getConfigHeaders } from "../api";

// const defaultUserInfo = {
//   name: "Appgain",
//   image:
//     "https://www.buckinghamandcompany.com.au/wp-content/uploads/2016/03/profile-placeholder.png",
// };

const AUTH_PENDING = "AUTH_PENDING";
const USER_LOGIN = "USER_LOGIN";
const LOGIN_ERROR = "LOGIN_ERROR";

export const checkUser = (username, pass) => async (dispatch, getState) => {
  dispatch({ type: AUTH_PENDING });
  try {
    const data = await appBackendAppgainUrl(
      `Auth/?where={"username": "${username}", "pass": "${pass}"}`,
      "get",
      getConfigHeaders()
    );
    console.log("loginData: ", data);
    sessionStorage.setItem("user", data.results[0].username);
    localStorage.setItem("avalible",data.results[0].available)
    if (data.results.length) {
      dispatch({
        type: USER_LOGIN,
        payload: data.results[0],
      });

    } else {
      dispatch({
        type: LOGIN_ERROR,
        error: "Username or password are wrong",
      });
    }
  } catch (error) {
    dispatch({
      type: LOGIN_ERROR,
      error,
    });
  }
};

export default function reducer(
  state = {
    user: {},
    isPending: false,
    error: null,
    isLoggedIn: false,
  },
  action
) {
  switch (action.type) {
    case AUTH_PENDING:
      return {
        ...state,
        isPending: true,
      };
    case USER_LOGIN:
      return {
        ...state,
        user: action.payload,
        isLoggedIn: true,
        isPending: false,
        error: null,

      };

    case LOGIN_ERROR:
      return {
        ...state,
        error: action.error,
        isPending: false,

      };
    default:
      return state;
  }
}
