import React, { useEffect, useState } from "react";
import "./Integrations.css";
import { connect } from "react-redux";
import {
  getIntegrations,
  deleteIntegration
} from "../../reducers/Integrations";
import IntegrationsDataTable from "../../components/IntegrationsDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import _ from 'lodash';
import axios from "axios";

const Integrations = ({
  integrations,
  getIntegrations,
  deleteIntegration,
  isPending,
  history,
}) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    getIntegrations();
  }, []);

  useEffect(() => {
    setData(integrations);
  }, [integrations])

  const handleAddCase = () => {
    history.push('addIntegration');
  }
  function reload(){

    try {
      
        fetch(
        'https://api.vercel.com/v1/integrations/deploy/prj_2avFJzRCmNi71mYejKrW91VtgFAa/j71wYLiEWQ',{
        method:'GET',
       headers: {
          'Content-Type': 'application/json',
        },
       })
       alert('publishing done successfully')

      } catch (error) {
        console.log("error", error);
        alert('failed')

    }
  }
	// .then(data => console.log(data))
	// .catch(err => console.error(err));
  const handlereload = ()=>{
    reload()
  }
 

  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <div className="card">
      <div className="header">
        <h4 className="title">
          Integrations
          <button
            onClick={handlereload}
            className="btn btn-primary addProjectBtn me-2"
          >
             publish to the website
          </button>

          <button
            onClick={handleAddCase}
            className="btn btn-primary addProjectBtn"
          >
            <i className="fa fa-plus"></i> Add an Integration
          </button>
        </h4>
      </div>
      <div
        className="content table-responsive table-full-width"
        style={{ width: "100%" }}
      >
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>Logo</th>
              <th>Name</th>
              <th>Type</th>
              <th>Description</th>
              <th>Created at</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) && data.map((integration) => (
              <IntegrationsDataTable
                key={integration.objectId}
                integration={integration}
                isPending={isPending}
                deleteIntegration={deleteIntegration}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    integrations: state.Integrations.integrations,
    isPending: state.Integrations.isPending,
    integration: state.Integrations.integration,
  }),
  (dispatch) => ({
    getIntegrations: () => dispatch(getIntegrations()),
    deleteIntegration: id => dispatch(deleteIntegration(id))
  })
)(Integrations);
