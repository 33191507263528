import { appBackendAppgainUrl, getConfigHeaders } from "../api";

const url = `Careers`;

const defaultState = {
  careers: [],
  career: {},
  error: null,
  isPending: null,
  loaded: false,
};

const GET_CAREERS = "GET_CAREERS";
const DELETE_CAREER = "DELETE_CAREER";
const CAREERS_PENDING = "CAREERS_PENDING";
const CAREER_PENDING = "CAREER_PENDING";
const GET_CAREER = "GET_CAREER";
const CAREER_ERROR = "CAREER_ERROR";
const CAREERS_ERROR = "CAREERS_ERROR";
const EDIT_CAREER = "EDIT_CAREER";
const CREATE_CAREER = "CREATE_CAREER";


// GET careers
export const getCareers = () => async (dispatch, getState) => {
  dispatch({
    type: CAREERS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "get",
      getConfigHeaders()
    );
    console.log(getState().Careers);
    dispatch({
      type: GET_CAREERS,
      payload: data.results,
    });
  } catch (error) {
    dispatch({
      type: CAREERS_ERROR,
      payload: error,
    });
  }
};

// GET career

export const getCareer = (personId) => async (dispatch, getState) => {
  dispatch({
    type: CAREER_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}/${personId}`,
      "get",
      getConfigHeaders()
    );
    dispatch({
      type: GET_CAREER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAREER_ERROR,
      payload: error,
    });
  }
};


// Create career
export const createCareer = (newCareerData) => async (dispatch, getState) => {
  dispatch({
    type: CAREERS_PENDING,
    payload: true,
  });
  try {
    const data = await appBackendAppgainUrl(
      `${url}`,
      "post",
      newCareerData,
      getConfigHeaders()
    );
    console.log(getState().Careers);
    dispatch({
      type: CREATE_CAREER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CAREERS_ERROR,
      payload: error,
    });
  }
};

export const editCareer = (id, newCareersData) => async (dispatch) => {
  dispatch({
    type: CAREER_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(
      `${url}/${id}`,
      "put",
      newCareersData,
      getConfigHeaders()
    );
    dispatch({
      type: EDIT_CAREER,
      payload: { id, newCareersData },
    });
  } catch (error) {
    dispatch({
      type: CAREER_ERROR,
      payload: error,
    });
  }
}

// Delete careers Person
export const deleteCareer = (id) => async (dispatch, getState) => {
  dispatch({
    type: CAREERS_PENDING,
    payload: true,
  });
  try {
    await appBackendAppgainUrl(`${url}/${id}`, "delete", getConfigHeaders());

    dispatch({
      type: DELETE_CAREER,
      payload: id,
    });
  } catch (error) {
    dispatch({
      type: CAREERS_ERROR,
      payload: error,
    });
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CAREER_PENDING:
    case CAREERS_PENDING:
      return { ...state, isPending: true };

    case GET_CAREERS:
      return {
        ...state,
        careers: action.payload,
        loaded: true,
        isPending: false,
      };

    case CREATE_CAREER:
      return {
        isPending: false,
        loaded: true
      }

    case EDIT_CAREER:
      return {
        ...state,
        careers: state.careers.map((memory) => (memory.objectId === action.payload.id
          ? action.payload.newCareersData : memory)),
        isPending: false,
        loaded: true
      };

    case GET_CAREER:
      return {
        ...state,
        career: action.payload,
        loaded: true,
        isPending: false
      };

    case DELETE_CAREER:
      return {
        ...state,
        careers: state.careers.filter(
          (person) => person.objectId !== action.payload
        ),
        loaded: true,
        isPending: false,
      };
    default:
      return { ...state };
  }
}
