import React from "react";
const Dashboard = () => {
  return (
    <div className="content welcome-content">
      <h2>Welcome to Appgain Dashboard</h2>
    </div>
  );
};

export default Dashboard;
