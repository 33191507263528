import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { editSuit, getSuits } from "../../reducers/Suits";

import "./suits.css";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import toaster from "../../modules/toaster";

const ListSuits = ({ getSuits, editSuit, suits, isPending }) => {
  const [isActive, setIsActive] = useState();
  useEffect(() => {
    getSuits();
  }, []);
  const editstatus=(id)=>{
    try {
      editSuit(id);
      toaster.success("editing successfully")
      getSuits();
    } catch (error) {
      toaster.error("something went wrong")

    }
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Subdomain",
      dataIndex: "subdomain",
      key: "subdomain",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record) => {
        setIsActive(record.status);
        return (
          <>
            <div
              className={`${isActive === "active" ? "isActive" : "notActive"}`}
              onClick={() => {
                editstatus(record.id);
               
              }}
            >
              <button className={`toggleBtn`}></button>
            </div>
          </>
        );
      },
    },
  ];
  if (isPending) {
    return <LoadingSpinner />;
  }

  return (
    <div className="card">
      <Table dataSource={suits.suits} scroll columns={columns}></Table>
    </div>
  );
};

export default connect(
  (state) => ({
    suits: state.Suits.suits,
    isPending: state.Suits.isPending,
  }),
  (dispatch) => ({
    getSuits: () => dispatch(getSuits()),
    editSuit: (id) => dispatch(editSuit(id)),
  })
)(ListSuits);
