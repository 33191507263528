import React, { useEffect, useState } from "react";
import "./Glossary.css";
import { connect } from "react-redux";
import {
  getGlossaries,
  deleteGlossary
} from "../../reducers/Glossary";
import GlossaryDataTable from "../../components/GlossaryDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import _ from 'lodash';

const Glossary = ({
  glossaries,
  getGlossaries,
  deleteGlossary,
  isPending,
  history,
}) => {

  const [data, setData] = useState([]);

  useEffect(() => {
    getGlossaries();
  }, []);

  useEffect(() => {
    setData(glossaries);
  }, [glossaries])

  const handleAddCase = () => {
    history.push('addGlossary');
  }
 

  function reload(){

    try {
      
        fetch(
        'https://api.vercel.com/v1/integrations/deploy/prj_2avFJzRCmNi71mYejKrW91VtgFAa/j71wYLiEWQ',{
        method:'GET',
       headers: {
          'Content-Type': 'application/json',
        },
       })
       alert('publishing done successfully')

      } catch (error) {
        console.log("error", error);
        alert('failed')

    }
  }
  if (isPending) { 
    return <LoadingSpinner />;
  }
  return (
    <div className="card">
      <div className="header">
        <h4 className="title">
          Glossary
          <button
            onClick={reload}
            className="btn btn-primary addProjectBtn me-2"
          >
            publish to the website
          </button>

          <button
            onClick={handleAddCase}
            className="btn btn-primary addProjectBtn"
          >
            <i className="fa fa-plus"></i> Add a Glossary
          </button>
         
        </h4>
      </div>
      <div
        className="content table-responsive table-full-width"
        style={{ width: "100%" }}
      >
        <table className="table table-hover table-striped">
          <thead>
            <tr>
              <th>ObjectId</th>
              <th>Name</th>
              <th>Description</th>
              <th>Content</th>
              <th>Created at</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) && data.map((glossary) => (
              <GlossaryDataTable
                key={glossary.objectId}
                glossary={glossary}
                isPending={isPending}
                deleteGlossary={deleteGlossary}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(
  (state) => ({
    glossaries: state.Glossary.glossaries,
    isPending: state.Glossary.isPending,
    glossary: state.Glossary.glossary,
  }),
  (dispatch) => ({
    getGlossaries: () => dispatch(getGlossaries()),
    deleteGlossary: id => dispatch(deleteGlossary(id))
  })
)(Glossary);
