import React, { useEffect, useState } from "react";
import "./Ebooks.css";
import { connect } from "react-redux";
import EbooksDataTable from "../../components/EbooksDataTable";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import _ from "lodash";
import { deleteEbook, getEbooks } from "../../reducers/Ebooks";

const Ebooks = ({ ebooks, getEbooks, deleteEbook, isPending, history }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    getEbooks();
  }, []);

  useEffect(() => {
    setData(ebooks);
  }, [ebooks]);

  const handleAddTeam = () => {
    history.push("addEbook");
  };

   function reload(){

    try {
      
        fetch(
        'https://api.vercel.com/v1/integrations/deploy/prj_2avFJzRCmNi71mYejKrW91VtgFAa/j71wYLiEWQ',{
        method:'GET',
       headers: {
          'Content-Type': 'application/json',
        },
       })
       alert('publishing done successfully')

      } catch (error) {
        console.log("error", error);
        alert('failed')

    }
  }

  if (isPending) {
    return <LoadingSpinner />;
  }
  return (
    <div className='card'>
      <div className='header'>
        <h4 className='title'>
          Ebooks
          <button
            onClick={reload}
            className="btn btn-primary addProjectBtn me-2"
          >
            publish to the website
          </button> 

          <button
            onClick={handleAddTeam}
            className='btn btn-primary addProjectBtn'
          >
            <i className='fa fa-plus'></i> Add an Ebook
          </button>
        </h4>
      </div>
      <div
        className='content table-responsive table-full-width'
        style={{ width: "100%" }}
      >
        <table className='table table-hover table-striped'>
          <thead>
            <tr>
              <th>Photo</th>
              <th>title</th>
              <th>description</th>
              <th>Link</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(data) &&
              data.map(ebook => (
                <EbooksDataTable
                  key={ebook.objectId}
                  ebook={ebook}
                  isPending={isPending}
                  deleteEbook={deleteEbook}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default connect(
  state => ({
    // ebooks: state,
    isPending: state.Ebooks.isPending,
    ebooks: state.Ebooks.ebooks,
  }),
  dispatch => ({
    getEbooks: () => dispatch(getEbooks()),
    deleteEbook: id => dispatch(deleteEbook(id)),
  })
)(Ebooks);
