import React, { useState } from "react";
import { Link } from 'react-router-dom';
import "./IntegrationsDataTable.css";
import dateFormatter from "../../helpers/dateFormatter";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import IconBtn from "../IconBtn/IconBtn";
import _ from 'lodash';

const IntegrationDataTable = ({
  integration,
  deleteIntegration
}) => {
  const [show, setShow] = useState(false);
  const openConfirmModalHandler = () => {
    setShow(true);
  };

  const deleteIntegrationHandler = () => {
    deleteIntegration(integration.objectId);
  }

  return (
    <React.Fragment>
      <tr>
        <td>
          <img
            className="integration-img"
            src={integration.Logo}
            alt={`${integration.Type}`}
          />
        </td>
        <td>{integration.Name}</td>
        <td>{integration.Type}</td>
        <td dangerouslySetInnerHTML={{ __html: integration.Description }}
        ></td>
        <td>{dateFormatter(integration.createdAt)}</td>
        <td>
          <Link to={`/editIntegration/${integration.objectId}`}>
            <IconBtn
              title={"Edit"}
              iconName={"pencil"}
              btnBehaviourClass="edit"
            />
          </Link>
        </td>
        <td>
          <IconBtn
            btnBehaviourClass={"delete"}
            iconName="trash"
            onClick={openConfirmModalHandler}
          />
        </td>

      </tr>

      {show && (
        <ConfirmModal
          handleClose={() => setShow(false)}
          handleShow={() => setShow(true)}
          message={`Are you sure you want to delete ${integration.Name}?`}
          setShow={setShow}
          show={show}
        >
          <button
            className="btn btn-danger"
            onClick={deleteIntegrationHandler}
          >
            Delete
          </button>
        </ConfirmModal>
      )}
    </React.Fragment>
  );
};

export default IntegrationDataTable;
