import "./AddCareer.css";
import { FormControl, FormGroup, Button } from "react-bootstrap";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import React, { useState, useEffect } from "react";

import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { connect } from "react-redux";
import { getCareer, editCareer, createCareer } from "../../reducers/Careers.js";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { stateToHTML } from "draft-js-export-html";
import _ from "lodash";
const AddCareer = ({
  history,
  isPending,
  career,
  getCareer,
  editCareer,
  createCareer,
}) => {
  const careerId =
    window.location.hash.includes("editCareer") &&
    window.location.hash.split("/")[window.location.hash.split("/").length - 1];
  console.log("careerId: ", careerId);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [description, setDescription] = useState(EditorState.createEmpty());
  const [isDescriptionRequired, setIsDescriptionRequired] = useState(false);
  const [careerName, setCareerName] = useState("");
  const [positionType, setPositionType] = useState("");
  const [positionLocation, setPositionLocation] = useState("");
  const [applyURL, setApplyURL] = useState("");
  const [edit, setEdit] = useState(false);
  const [openPositionType, setOpenPositionType] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    if (careerId) {
      getCareer(careerId);
      setIsEditingMode(true);
    } else {
      setIsEditingMode(false);
    }
  }, [careerId]);

  const cancel = () => {
    history.go(-1);
    // Integrations, Experts, Testimonial, Customer, Career, Glossary
    // Demo
  };

  const submitCareersPersonDataHandler = (e) => {
    e.preventDefault();
    if (description.getCurrentContent().getPlainText().trim().length <= 0) {
      setIsDescriptionRequired(true);
      return;
    }
    const newCareerData = {
      PositionName: careerName,
      Description: stateToHTML(description.getCurrentContent()),
      Type: positionType,
      PositionLocation: positionLocation,
      ApplyURL: applyURL,
      Type: positionType,
    };

    console.log(newCareerData);

    if (isEditingMode) {
      editCareer(careerId, newCareerData);
    } else if (!isEditingMode && !_.isEmpty(positionType)) {
      createCareer(newCareerData);
    } else {
      setError("Position Type is required");
      return;
    }
    history.push("/careers");
  };

  useEffect(() => {
    if (!!career && careerId) {
      setCareerName(career.PositionName);

      setDescription(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              career.Description ? career.Description : `<p>No Description</p>`
            )
          )
        )
      );
      setPositionType(career.Type);
      setPositionLocation(career.PositionLocation);
      setApplyURL(career.ApplyURL);
      setEdit(true);
    }
  }, [career]);

  const handlePositionTypeChange = (e) => {
    setPositionType(e.target.value);
  };

  if (isPending) return <LoadingSpinner />;

  return (
    <div>
      <h2 className="title">
        {isEditingMode ? `Edit career: ${careerName}` : "Add New career"}
      </h2>
      <form
        className="container"
        style={{ minHeight: "auto" }}
        onSubmit={submitCareersPersonDataHandler}
      >
        <FormGroup>
          <div className="fieldDiv">
            <label id="careerName">Position Name</label>
            <FormControl
              type="text"
              value={careerName}
              onChange={(e) => setCareerName(e.target.value)}
              name="careerName"
              htmlFor="careerName"
              placeholder="Enter Career Name"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="positionLocation">Position Location</label>
            <FormControl
              type="text"
              value={positionLocation}
              onChange={(e) => setPositionLocation(e.target.value)}
              name="positionLocation"
              htmlFor="positionLocation"
              placeholder="Enter Position Location"
              required
            />
          </div>

          <div className="fieldDiv">
            <label id="applyURL">Apply URL</label>
            <FormControl
              type="text"
              value={applyURL}
              onChange={(e) => setApplyURL(e.target.value)}
              name="applyURL"
              htmlFor="applyURL"
              placeholder="Enter Apply URL"
              required
            />
          </div>

          <div>
            <div className="fieldDiv">
              <label id="positionType">Position Type</label>
              <Select
                labelId="positionType"
                id="positionType"
                open={openPositionType}
                onClose={() => setOpenPositionType(false)}
                onOpen={() => setOpenPositionType(true)}
                value={positionType || ""}
                onChange={handlePositionTypeChange}
                style={{ padding: "5px 10px" }}
              >
                <MenuItem value="internship">Internship</MenuItem>
                <MenuItem value="part-time">Part Time</MenuItem>
                <MenuItem value="full-time">Full Time</MenuItem>
              </Select>
            </div>
          </div>

          <div className="fieldDiv">
            <label id="personDescription">Description</label>
            <Editor
              editorState={description}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={(e) => setDescription(e)}
              onFocus={() => setIsDescriptionRequired(false)}
              onBlur={() =>
                description.getCurrentContent().getPlainText().trim().length <=
                  0 && setIsDescriptionRequired(true)
              }
            />

            {isDescriptionRequired && (
              <p
                style={{
                  color: "red",
                  textAlign: "center",
                  fontWeight: 700,
                }}
              >
                Description field is required
              </p>
            )}
          </div>
        </FormGroup>
        <div className="btns">
          <Button className="submitBtn" type="submit" bsStyle="primary">
            {isEditingMode ? "Edit" : "Submit"}
          </Button>
          <Button className="CancelBtn" bsStyle="primary" onClick={cancel}>
            Cancel
          </Button>
        </div>
        <div className="error">
          <p>{error}</p>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isPending: state.Careers.isPending,
  career: state.Careers.career,
});

const actions = (dispatch) => ({
  getCareer: (careerId) => dispatch(getCareer(careerId)),
  editCareer: (careerId, newCareerData) =>
    dispatch(editCareer(careerId, newCareerData)),
  createCareer: (newCareerData) => dispatch(createCareer(newCareerData)),
});

export default connect(mapStateToProps, actions)(AddCareer);
