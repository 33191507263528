import React, { Component, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { updateCases } from "../../reducers/Integrations";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import "../../assets/styles/base.scss";
const Nav = ({ location, mobileMenu, user }) => {
  const [productPage, setProductPage] = useState(false);
  console.log(user);
  const useStyles = makeStyles((theme) => ({
    updateProjectButton: {
      width: "100%",
      marginBottom: "1rem",
    },
    updateCasesButton: {
      width: "100%",
    },
  }));
  const classes = useStyles();
  const updateWebsiteBlogs = () => {
    try {
      fetch("https://blogscms.appgain.io/blogs/update", {
        method: "POST",
        //  headers: {
        //     'Content-Type': 'application/json',
        //   },
      });
      alert("publishing done successfully");
    } catch (error) {
      console.log("error", error);
      alert("failed");
    }
  };
  // localStorage.getItem("avalible")?.split(",");
  // user.available.split()
  const linksAvailable = localStorage.getItem("avalible")?localStorage.getItem("avalible")?.split(","):[""]
  return (
    <ul className="nav">
      {linksAvailable.includes("glossary") && (
        <li className={location.pathname === "/glossary" ? "active" : null}>
          <Link to="/glossary">
            <i className="fa fa-file-text-o"></i>
            <p>Glossary</p>
          </Link>
        </li>
      )}
      {linksAvailable.includes("integrations") && (
        <li className={location.pathname === "/integrations" ? "active" : null}>
          <Link to="/integrations">
            <i className="fa fa-heart"></i>
            <p>Integrations</p>
          </Link>
        </li>
      )}
      {linksAvailable.includes("careers") && (
        <li className={location.pathname === "/careers" ? "active" : null}>
          <Link to="/careers">
            <i className="fa fa-user"></i>
            <p>Careers</p>
          </Link>
        </li>
      )}
      {linksAvailable.includes("demos") && (
        <li className={location.pathname === "/demos" ? "active" : null}>
          <Link to="/demos">
            <i className="fa fa-sitemap"></i>
            <p>Demo Center</p>
          </Link>
        </li>
      )}
      {linksAvailable.includes("experts") && (
        <li className={location.pathname === "/experts" ? "active" : null}>
          <Link to="/experts">
            <i className="fa fa-id-card-o"></i>
            <p>Experts</p>
          </Link>
        </li>
      )}
      {linksAvailable.includes("customers") && (
        <li className={location.pathname === "/customers" ? "active" : null}>
          <Link to="/customers">
            <i className="fa fa-video-camera"></i>
            <p>Customers</p>
          </Link>
        </li>
      )}
      {linksAvailable.includes("testimonials") && (
        <li className={location.pathname === "/testimonials" ? "active" : null}>
          <Link to="/testimonials">
            <i className="fa fa-sticky-note-o"></i>
            <p>Testimonials</p>
          </Link>
        </li>
      )}
      {linksAvailable.includes("teams") && (
        <li className={location.pathname === "/teams" ? "active" : null}>
          <Link to="/teams">
            <i className="fa fa-users"></i>
            <p>Teams</p>
          </Link>
        </li>
      )}
      {linksAvailable.includes("useCases") && (
        <li className={location.pathname === "/useCases" ? "active" : null}>
          <Link to="/useCases">
            <i className="fa fa-floppy-o"></i>
            <p>Use Cases</p>
          </Link>
        </li>
      )}
      {linksAvailable.includes("ebooks") && (
        <li className={location.pathname === "/ebooks" ? "active" : null}>
          <Link to="/ebooks">
            <i className="fa fa-book" aria-hidden="true"></i>
            <p>Ebooks</p>
          </Link>
        </li>
      )}
      {(linksAvailable.includes("plans") ||
        linksAvailable.includes("scheduledJobs") ||
        linksAvailable.includes("suits")) && (
        <li
          className="dropDown"
          onClick={() => {
            productPage ? setProductPage(false) : setProductPage(true);
          }}
        >
          <i className="fa fa-arrow-circle-down" aria-hidden="true"></i>
          <p>Product Admin</p>
        </li>
      )}

      {productPage && (
        <ul className="submenue">
          {linksAvailable.includes("plans") && (
            <li className={location.pathname === "/plans" ? "active" : null}>
              <Link to="/plans">
                <i className="fa-area-chart" aria-hidden="true"></i>
                <p>Plans</p>
              </Link>
            </li>
          )}
          {linksAvailable.includes("scheduledJobs") && (
            <li
              className={
                location.pathname === "/scheduledJobs" ? "active" : null
              }
            >
              <Link to="/scheduledJobs">
                <i className="fa-area-chart" aria-hidden="true"></i>
                <p>scheduled Jobs</p>
              </Link>
            </li>
          )}
          {linksAvailable.includes("suits") && (
            <li className={location.pathname === "/suits" ? "active" : null}>
              <Link to="/suits">
                <i className="fa-area-chart" aria-hidden="true"></i>
                <p>Users</p>
              </Link>
            </li>
          )}
        </ul>
      )}
      {linksAvailable.includes("notifications") && (
        <li
          className={location.pathname === "/notifications" ? "active" : null}
        >
          <Link to="/notifications">
            <i className="fa fa-bell"></i>
            <p>Notifications</p>
          </Link>
        </li>
      )}

      <li>
        <Button
          variant="contained"
          color="primary"
          onClick={() => updateWebsiteBlogs()}
          className={classes.updateProjectButton}
        >
          Update Website Blogs
        </Button>
      </li>
    </ul>
  );
};
const mapStateToProps = (state) => ({
  user: state.Auth.user,
});

export default compose(withRouter, connect(mapStateToProps))(Nav);
